.popup {
    display: none;
    position: fixed;
    z-index: 99999;
    padding-top: 100px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: #000;
    background-color: rgba(0,0,0,.4);
}
.popup-content {
    background-color: #fefefe;
    border-radius: 1rem;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    font-size: .85rem;
    width: 50%;
}
.popup-content-30 {
    background-color: #fefefe;
    border-radius: 1rem;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    font-size: .85rem;
    width: 30%;
}
.popup-content-34 {
    background-color: #fefefe;
    border-radius: 1rem;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    font-size: .85rem;
    width: 34%;
}

.popup-content-60
{
    background-color: #fefefe;
    border-radius: 1rem;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    font-size: .85rem;
    width: 60%;
}


.popup-content-90
{
    background-color: #fefefe;
    border-radius: 1rem;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    font-size: .85rem;
    width: 90%;
}
.popup-content-80
{
    background-color: #fefefe;
    border-radius: 1rem;
    margin: auto;
    padding: 10px;
    border: 1px solid #888;
    font-size: .85rem;
    width: 80%;
}
.popup-content-75
{
    background-color: #fefefe;
    border-radius: 1rem;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    font-size: .85rem;
    width: 75%;
}
.popup-content-85
{
    background-color: #fefefe;
    border-radius: 1rem;
    margin: auto;
    padding: 10px;
    border: 1px solid #888;
    font-size: .85rem;
    width: 85%;
}
.popup-content-50
{
    background-color: #fefefe;
    border-radius: 1rem;
    margin: auto;
    padding: 10px;
    border: 1px solid #888;
    font-size: .85rem;
    width: 50%;
}
.popup-content-32
{
    background-color: #fefefe;
    border-radius: 1rem;
    margin: auto;
    padding: 10px;
    border: 1px solid #888;
    font-size: .85rem;
    width: 32%;
}
.popup-content-27
{
    background-color: #fefefe;
    border-radius: 1rem;
    margin: auto;
    padding: 10px;
    border: 1px solid #888;
    font-size: .85rem;
    width: 27%;
}

/* .popup-content {
    width: 36%;
} */
.popup-open
{
    display:'block'
}
.popup-close
{
    display:'none'
}
.popup-line
{
    margin-top:10px;
    background-color:lightgray;
    min-height:1px;
    min-width:100%
}
.close {
    color: #aaa;
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1.4rem;
    cursor: pointer;
}
.shadow {
    box-shadow: 0 .15rem 1.75rem 0 rgba(58,59,69,.15)!important;
}
.popup-button-container
{
    display: flex;
    padding-left: 37%;
    padding-top: 20px;
}
.button-active
{
    background-color: #448D48;
    height: 31px;
    width: 100px;
    border: 0px;
    border-radius: 5px;
    
    color: white;
    text-align: center;
    
}
.button-active:hover
{
    background-color: #50a154;
    cursor: pointer;
}
.button-deactive
{
    background-color: #BD1E2D;
    height: 31px;
    width: 100px;
    border: 0px;
    border-radius: 5px;
    margin-left: 10px;
    color: white;
    text-align: center;
}
.button-deactive:hover
{
    background-color:#db283a;
    cursor: pointer;
}
.custom-height-149
{
    height: 149px;
}
.custom-height-241
{
    height: 241px;
}
.custom-height-341
{
    height: 341px;
}
.custom-height-200
{
    height: 200px;
}
