.profile-container{
    display:flex;
    flex-wrap: wrap;
}
.value-parent{
    margin:10px 0px;
    margin-right:20px;
}
.heading-parent{
    width:100%;
    background-color: #eee;
    padding:5px;
    border-radius: 5px;
}
.full-width{
    width:100%;
}
.welcome-message{
    margin:10px 0px;
    font-size: 14px;
    font-weight:bold;
}